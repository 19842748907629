<template>
  <v-container style="min-height: 100vh">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="primary"
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <BudgetsList />
      </v-tab-item>
      <v-tab-item>
        <OrdersList />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import BudgetsList from '../../components/my-profile/BudgetsList.vue'
import OrdersList from '../../components/my-profile/OrdersList.vue'

export default {
  components: {
    BudgetsList,
    OrdersList,
  },
  data() {
    return {
      tab: null,
      items: ['Orçamentos', 'Pedidos'],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    }
  },
}
</script>
